<!--地标地图详情-->
<template>
  <div class="cityDetailDiv">
    <!--面包屑-->
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <div class="detailDiv">
      <!--<div class="city-title">
        <span>{{title}}</span>
      </div>
      &lt;!&ndash;内容部分&ndash;&gt;
      <div class="content-div">
        &lt;!&ndash;<div class="content-left">
          <img :src="img" alt="">
          <div class="subTitleDiv">
            {{subTitle}}
          </div>
        </div>&ndash;&gt;
        <div class="content-right">
          <div class="content-text">
            {{content}}
          </div>
        </div>
      </div>-->
      <el-row>
        <el-col :span="9">
          <el-carousel class="carouselImgs" indicator-position="inside">
            <el-carousel-item v-for="(item,index) in imgCarousel" :key="index">
              <img :src="item" alt="" class="top-img">
            </el-carousel-item>
          </el-carousel>
        </el-col>
        <el-col :span="14" :offset="1" class="textAlignLeft">
          <el-row>
            <el-col :span="24">
              <div class="infoTitle">{{title}}</div>
            </el-col>
          </el-row>
          <div class="remarkDiv">{{content}}</div>
        </el-col>
      </el-row>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
export default {
  name: "giMapDetail",
  components: { topPathList },
  data() {
    return {
      pathObj: [],
      title: "", //标题
      subTitle: "",
      content: "",
      img: "", //图片地址
      imgCarousel: [], //产品图片
    }
  },
  methods: {
  },
  mounted() {
    this.pathObj = this.$route.query.pathObj;
    this.title = this.$route.query.title
    this.content = this.$route.query.content
    this.imgCarousel = this.$route.query.pic.split(',')
  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .cityDetailDiv {
    text-align: center;
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  .detailDiv {
    min-height: 483px;
    margin: auto;
    padding: 32px 40px 12px 40px;
    width: 1120px;
    background: #FFFFFF;
    .city-title {
      clear: both;
      font-size: 35px;
      font-weight: bold;
    }
    .content-div {
      min-height: 461px;
      display: flex;
      margin: 20px;
      /*.content-left {
        img {
          width: 455px;
          height: 285px;
        }
        .subTitleDiv {
          font-size: 18px;
        }
      }*/
      .content-right {
        margin-left: 20px;
        .content-text {
          text-align: left;
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }

  .carouselImgs {
    overflow: hidden;
    width: 433px;
    height: 330px;
  }
  .top-img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  .infoTitle {
    text-align: left;
    margin: 10px 0 10px 0;
    font-size: 28px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
  }
  .remarkDiv {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 29px;
  }

  //轮播图指示器设置为圆点
  ::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
    width: 10px;
    height: 10px;
    background: #dee9f6;
    border: 1px solid #dee9f6;
    border-radius: 50%;
    opacity: 0.5;
  }
  ::v-deep .el-carousel__indicator--horizontal.is-active .el-carousel__button{
    width: 10px;
    height: 10px;
    background: #186CF5;
    border-radius: 50%;
    opacity: 1;
  }
  ::v-deep .el-carousel__indicators--horizontal {
    left: 81% !important;
    top: 80% !important;
  }

</style>
